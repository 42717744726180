import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Yup from "yup";
import { Col, FormGroup, FormInput, FormSelect, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { DateTimeInput, FileDropZone, Form, ModalWindow, TagsInput } from "lib/common";
import { FiFile, FiFolder, FiFolderPlus } from "react-icons/fi";
import { bind } from "decko";
import { connect } from "react-redux";
import { getArchiveData } from "selectors/archive";
import { getTextsData } from "selectors/texts";
import ArchiveActions from "actions/ArchiveActions";
import DataConstants from "const/DataConstants";
import EditVaultFileWindowCommentsBlock from "./lib/EditVaultFileWindowCommentsBlock";
import EditVaultFileWindowModeSelector, {
  EDIT_VAULT_FILE_WINDOW_MODES
} from "./lib/EditVaultFileWindowModeSelector";
import React, { PureComponent } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { TAX_STATEMENT, AGREEMENT, BANK_STATEMENT, OTHER } = DataConstants.ARCHIVE_ATTACHMENT_TYPES;

const mapStateToProps = (state) => ({
  textsData: getTextsData(state),
  archiveData: getArchiveData(state)
});

const mapDispatchToProps = (dispatch) => ({
  downloadFile: (...args) => dispatch(ArchiveActions.downloadFile(...args)),
  showModal: (...args) => dispatch(UiActions.showModal(...args))
});

@connect(mapStateToProps, mapDispatchToProps)
class EditVaultFileWindow extends PureComponent {
  static VALIDATION_SCHEMA = Yup.object().shape({
    id: Yup.string().nullable(),
    type: Yup.string().required(),
    fromDate: Yup.string(),
    toDate: Yup.string(),
    description: Yup.string().trim(),
    tags: Yup.array().of(Yup.string().trim()),
    path: Yup.array().of(Yup.string().trim())
  });

  static BANK_STATEMENT_VALIDATION_SCHEMA = Yup.object().shape({
    id: Yup.string().nullable(),
    type: Yup.string().required(),
    fromDate: Yup.string().required(),
    toDate: Yup.string().required(),
    description: Yup.string().trim(),
    tags: Yup.array().of(Yup.string().trim()),
    path: Yup.array().of(Yup.string().trim())
  });

  tagsList = null;

  initialStateSnapshot = null;

  constructor(props) {
    super(props);

    const { initialTab, folderPath, additionalData, editData = {} } = this.props;

    this.tagsList = [...new Set(this.props.archiveData.reduce((aggregator, { tags }) => [...aggregator, ...tags], []))];

    this.state = {
      files: null,
      id: editData.id || null,
      type: editData.type || "",
      fromDate: editData.fromDate || "",
      toDate: editData.toDate || "",
      description: editData.description || "",
      tags: editData.tags || [],
      path: editData.path || (additionalData ? [...folderPath, additionalData.folderName] : folderPath),
      selectedEditTab: initialTab || EDIT_VAULT_FILE_WINDOW_MODES.DEFAULT
    };
    this.initialStateSnapshot = JSON.parse(JSON.stringify(this.state));
  }

  getVaultAttachmentTypes() {
    const { textsData: { uiTexts } } = this.props;

    return [
      { value: TAX_STATEMENT, label: uiTexts.taxStatement },
      { value: AGREEMENT, label: uiTexts.agreement },
      { value: BANK_STATEMENT, label: uiTexts.bankStatement },
      { value: OTHER, label: uiTexts.other }
    ];
  }

  checkFormValidity({ files, ...restState }) {
    if (this.props.editData || files) {
      return restState.type === BANK_STATEMENT
        ? EditVaultFileWindow.BANK_STATEMENT_VALIDATION_SCHEMA.isValidSync(restState)
        : EditVaultFileWindow.VALIDATION_SCHEMA.isValidSync(restState);
    }

    return false;
  }

  checkFormHasChanges() {
    const { selectedEditTab: removeA, files: removeB, ...restState } = this.state;

    const { selectedEditTab: removeC, files: removeD, ...restInitialStateSnapshot } = this.initialStateSnapshot;

    return !Utils.checkDeepEquality(restState, restInitialStateSnapshot);
  }

  @bind
  handleClose(result) {
    (async() => {
      const { textsData: { uiTexts, messages }, editData, showModal, onClose } = this.props;

      let modalResult = result;

      if (!result && editData && this.checkFormHasChanges() && this.checkFormValidity(this.state)) {
        modalResult = await showModal(messages.unsavedChangesWarning, uiTexts.warning, true, "sm", uiTexts.yes, uiTexts.no);
      }
      onClose(modalResult && { ...this.state });
    })();
  }

  @bind
  handleFileDropZoneDrop(files, fileRejections) {
    if (files.length) this.setState({ files });
    else if (fileRejections.length) this.setState({ files: null });
  }

  @bind
  handleFileLinkClick(event) {
    event.preventDefault();

    const { editData: { id: fileId, attachment = {} } = {}, downloadFile } = this.props;

    downloadFile(fileId, attachment);
  }

  @bind
  handleFromDateInputChange(value) {
    const formattedValue = value ? Utils.formatApiDate(value) : "";

    this.setState(
      ({ toDate }) => {
        toDate = !value || !toDate || moment.utc(toDate).isBefore(moment.utc(formattedValue)) ? formattedValue : toDate;

        return { fromDate: formattedValue, toDate };
      }
    );
  }

  @bind
  handleToDateInputChange(value) {
    const formattedValue = value ? Utils.formatApiDate(value) : "";

    this.setState(
      ({ fromDate }) => {
        fromDate = !value || !fromDate || moment.utc(formattedValue).isBefore(moment.utc(fromDate)) ? formattedValue : fromDate;

        return { toDate: formattedValue, fromDate };
      }
    );
  }

  @bind
  handleTypeSelectChange({ target: { value } }) {
    this.setState({ type: value });
  }

  @bind
  handleDescriptionInputChange({ target: { value } }) {
    this.setState({ description: value });
  }

  @bind
  handleTagsInputChange({ target: { value } }) {
    this.setState({ tags: value || [] });
  }

  @bind
  handleModeSelectorChange(selectedEditTab) {
    this.setState({ selectedEditTab });
  }

  renderTabs() {
    const { editData } = this.props;

    return (
      <FormGroup row className={CommonCss.flexCenter}>
        <Row>
          <Col>
            <EditVaultFileWindowModeSelector
              comments={editData.comments}
              selectedEditTab={this.state.selectedEditTab}
              onChange={this.handleModeSelectorChange} />
          </Col>
        </Row>
      </FormGroup>
    );
  }

  renderEditVaultFileRows() {
    const { textsData: { uiTexts }, additionalData, editData } = this.props;

    const { files, fromDate, toDate, type, description, tags, path } = this.state;

    return (
      <Form>
        <FormGroup row className={Css.folderPathGroup}>
          <div>
            {additionalData ? <FiFolderPlus /> : <FiFolder />}
            <span>
              <b>{`${uiTexts.folder}: `}</b>
              <span>{`/${path.join("/")}`}</span>
            </span>
          </div>
        </FormGroup>
        {editData
          ? <FormGroup row>
            <Row form>
              <Col>
                <div className={Css.fileLinkContainer} onClick={this.handleFileLinkClick}>
                  <a>
                    <FiFile />
                    <span>{editData.attachment.originalName || uiTexts.download}</span>
                  </a>
                </div>
              </Col>
            </Row>
          </FormGroup>
          : <FormGroup row>
            <FileDropZone onDrop={this.handleFileDropZoneDrop} />
          </FormGroup>}
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>{uiTexts.fromDate}</InputGroupText>
                </InputGroupAddon>
                <DateTimeInput
                  selectsStart
                  startDate={fromDate}
                  endDate={toDate}
                  placeholder={uiTexts.enterDate}
                  disabled={!editData && !files}
                  value={fromDate}
                  invalid={type === BANK_STATEMENT && !fromDate}
                  onChange={this.handleFromDateInputChange} />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>{uiTexts.type}</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  placeholder={type ? undefined : ""}
                  value={type}
                  invalid={!type}
                  disabled={!editData && !files}
                  onChange={this.handleTypeSelectChange}>
                  {!type && <option value="">{uiTexts.selectType}</option>}
                  {this.getVaultAttachmentTypes().map(({ value, label }) => {
                    return <option key={value} value={value}>{label}</option>;
                  })}
                </FormSelect>
              </InputGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>{uiTexts.toDate}</InputGroupText>
                </InputGroupAddon>
                <DateTimeInput
                  selectsEnd
                  startDate={fromDate}
                  endDate={toDate}
                  placeholder={uiTexts.enterDate}
                  disabled={!editData && !files}
                  value={toDate}
                  invalid={type === BANK_STATEMENT && !toDate}
                  onChange={this.handleToDateInputChange} />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>{uiTexts.description}</InputGroupText>
                </InputGroupAddon>
                <FormInput
                  placeholder={uiTexts.enterDescription}
                  disabled={!editData && !files}
                  value={description}
                  onChange={this.handleDescriptionInputChange} />
              </InputGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <span>{uiTexts.tags}</span>
                  </InputGroupText>
                </InputGroupAddon>
                <TagsInput
                  placeholder={uiTexts.addTags}
                  autoCompleteData={this.tagsList}
                  disabled={!editData && !files}
                  value={tags}
                  onChange={this.handleTagsInputChange} />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    );
  }

  renderShowCommentsBlock() {
    return <EditVaultFileWindowCommentsBlock fileId={this.props.editData.id} />;
  }

  render() {
    const { DEFAULT, COMMENTS } = EDIT_VAULT_FILE_WINDOW_MODES;

    const { textsData: { uiTexts }, editData } = this.props;

    const { selectedEditTab } = this.state;

    const renderEditModeBlock = {
      [DEFAULT]: () => this.renderEditVaultFileRows(),
      [COMMENTS]: () => this.renderShowCommentsBlock()
    }[selectedEditTab];

    return (
      <ModalWindow
        applyOnEnterPress
        className={classNames(Css.editVaultFileWindow, selectedEditTab === COMMENTS && Css.commentsMode)}
        config={{
          confirm: selectedEditTab === DEFAULT,
          headerText: editData ? uiTexts.fileInfo : uiTexts.uploadFiles,
          okButtonText: editData ? uiTexts.save : uiTexts.add
        }}
        disabledOkButton={!this.checkFormValidity(this.state) || (editData && !this.checkFormHasChanges())}
        iconComponent={FiFile}
        onClose={this.handleClose}>
        {editData && this.renderTabs()}
        {renderEditModeBlock()}
      </ModalWindow>
    );
  }
}

export default EditVaultFileWindow;
