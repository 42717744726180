import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Col, FormGroup, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row, Tooltip } from "shards-react";
import { FiAlertTriangle, FiFolderPlus } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { bind } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import React, { PureComponent } from "react";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class CreateFolderWindow extends PureComponent {
  static EXCLUDED_CHARACTERS_PATTERN = /[.,/\\]+/g;

  state = { folderName: "" };

  @bind
  handleClose(result) {
    this.props.onClose(result ? this.state.folderName.trim() : result);
  }

  @bind
  handleFolderNameInputChange({ target: { value } }) {
    this.setState({ folderName: value.replace(CreateFolderWindow.EXCLUDED_CHARACTERS_PATTERN, "") });
  }

  render() {
    const { textsData: { uiTexts }, folders } = this.props;

    const { folderName } = this.state;

    const uniqueFolderName = !folders.some(({ name }) => {
      return name && folderName && name.trim().toLowerCase() === folderName.trim().toLowerCase();
    });

    return (
      <ModalWindow
        applyOnEnterPress
        className={Css.createFolderWindow}
        config={{
          confirm: true,
          headerText: uiTexts.createFolder,
          okButtonText: uiTexts.create
        }}
        disabledOkButton={!folderName.trim() || !uniqueFolderName}
        iconComponent={FiFolderPlus}
        onClose={this.handleClose}>
        {({ active }) => {
          return (
            <Form onSubmit={this.handleClose}>
              <FormGroup row className={CommonCss.flexCenter}>
                <Row form>
                  <Col>
                    <InputGroup>
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <span>{uiTexts.folder}</span>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        id="editFolderName"
                        placeholder={uiTexts.enterFolderName}
                        value={folderName}
                        onChange={this.handleFolderNameInputChange} />
                      <Tooltip open={!uniqueFolderName && active} target="#editFolderName">
                        <b className={CommonCss.negativeText}>
                          <FiAlertTriangle />
                          <span>{uiTexts.nameIsNotUnique}</span>
                        </b>
                      </Tooltip>
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          );
        }}
      </ModalWindow>
    );
  }
}

export default CreateFolderWindow;
