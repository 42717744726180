import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { ActionsButtons, Badge, Card, DropDownSelect, Page, Preloader } from "lib/common";
import { Button, CardBody } from "shards-react";
import { FiArrowLeft, FiFile, FiFolder, FiFolderPlus, FiLoader, FiPlusCircle, FiSave, FiTrash2 as FiTrash } from "react-icons/fi";
import { bind, memoize } from "decko";
import { checkArchiveFetching, getArchiveData, getUploadingArchiveFilesCount } from "selectors/archive";
import { checkModalContentShown } from "selectors/ui";
import { connect } from "react-redux";
import { getEnvVars } from "selectors/envVars";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRestrictions } from "selectors/user";
import AddTagsWindow from "lib/windows/AddTagsWindow";
import ArchiveActions from "actions/ArchiveActions";
import ChangeFolderSelector from "./lib/ChangeFolderSelector";
import Constants from "const/Constants";
import CreateFolderWindow from "./lib/CreateFolderWindow";
import DataConstants from "const/DataConstants";
import EditVaultFileWindow from "./lib/EditVaultFileWindow";
import MediaQuery from "react-responsive";
import PageActionsRow from "lib/common/PageActionsRow";
import PageHeader from "lib/common/PageHeader";
import Pages from "lib/pages/Pages";
import React, { PureComponent } from "react";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import VaultFilter from "./lib/VaultFilter";
import VaultTable from "./lib/VaultTable";
import moment from "moment";

const mapStateToProps = (state) => ({
  fetchingData: checkArchiveFetching(state),
  modalContentShown: checkModalContentShown(state),
  selectedBusinessId: getSelectedBusinessId(state),
  envVars: getEnvVars(state),
  archiveData: getArchiveData(state),
  textsData: getTextsData(state),
  userRestrictions: getUserRestrictions(state),
  archiveFilesCurrentUploadCount: getUploadingArchiveFilesCount(state)
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (...args) => dispatch(UiActions.showModal(...args)),
  toggleModalContent: (...args) => dispatch(UiActions.toggleModalContent(...args)),
  downloadFile: (...args) => dispatch(ArchiveActions.downloadFile(...args)),
  uploadFiles: (...args) => dispatch(ArchiveActions.uploadFiles(...args)),
  createFolder: (...args) => dispatch(ArchiveActions.createFolder(...args)),
  editFile: (...args) => dispatch(ArchiveActions.editFile(...args)),
  deleteFile: (...args) => dispatch(ArchiveActions.deleteFile(...args)),
  bulkFilesUpdate: (...args) => dispatch(ArchiveActions.bulkFilesUpdate(...args)),
  fetchFilesList: (...args) => dispatch(ArchiveActions.fetchFilesList(...args))
});

const { FOLDER } = DataConstants.ARCHIVE_ATTACHMENT_TYPES;

@connect(mapStateToProps, mapDispatchToProps)
class VaultPage extends PureComponent {
  static PARENT_FOLDER_RELATIVE_PATH = "..";

  static ADD_FILES_WINDOW_FOLDER_MODES = {
    CURRENT: "current",
    NEW: "new"
  };

  constructor(props) {
    super(props);

    const { envVars: { editItem, fromDate, toDate, text, type } } = this.props;

    const [editableVaultFileId, editableVaultFileTab] = editItem ? editItem.split(".") : [null, null];

    const validDateRange = moment(fromDate).isValid() && moment(toDate).isValid();

    this.state = {
      filters: {
        fromDate: validDateRange ? fromDate : undefined,
        toDate: validDateRange ? toDate : undefined,
        text,
        type
      },
      selectedItems: [],
      createFolderWindowOpened: false,
      addVaultFileWindowOpened: false,
      editVaultFileWindowOpened: !!editableVaultFileId,
      addTagsWindowOpened: false,
      addVaultFileWindowData: null,
      editableVaultFileData: editableVaultFileId ? {
        id: editableVaultFileId,
        tab: editableVaultFileTab
      } : null
    };
  }

  getFolderPath(folder) {
    const { restPath: folderPath } = Pages.getCurrentRouteInfo(this.props.location.pathname);

    if (folder === VaultPage.PARENT_FOLDER_RELATIVE_PATH) return folderPath.slice(0, folderPath.length - 1);

    return [...folderPath, folder];
  }

  getVaultFileDataById(fileId) {
    return this.props.archiveData.find(({ id }) => id === fileId);
  }

  async createFolderIfNeed(targetPath = []) {
    const { archiveData, createFolder } = this.props;

    if (targetPath.length && !archiveData.filter(({ path = [] }) => this.checkFolderMatch([targetPath, path, true])).length) {
      await createFolder({ path: targetPath });
    }
  }

  @memoize
  checkFolderMatch([folderPath, path = [], strict = false]) {
    if (strict) return folderPath.toString() === path.toString();

    return path.toString().indexOf(folderPath.toString()) === 0;
  }

  componentDidMount() {
    this.props.fetchFilesList(true, false);
  }

  @bind
  handleVaultFilterChange(filters) {
    this.setState({ filters }, () => {
      this.props.history.replace(`?${Utils.objectToQueryString({ ...filters })}`);
    });
  }

  @bind
  handleTableItemsSelect(selectedItems) {
    this.setState({ selectedItems });
  }

  @bind
  handleAddFilesSelectChange(mode) {
    const { ADD_FILES_WINDOW_FOLDER_MODES } = VaultPage;

    this.props.toggleModalContent(true);
    if (mode === ADD_FILES_WINDOW_FOLDER_MODES.NEW) {
      this.setState({ createFolderWindowOpened: true, addVaultFileWindowOpened: true });
    } else this.setState({ addVaultFileWindowOpened: true });
  }

  @bind
  handleFolderButtonClick({ currentTarget: { dataset: { folder } } }) {
    const { history, selectedBusinessId } = this.props;

    history.push(`/${selectedBusinessId + UiRoutes.ARCHIVE}/${this.getFolderPath(folder).join("/")}`);
  }

  @bind
  handleDeleteClick(event) {
    event.stopPropagation();

    const { currentTarget: { dataset: { folder } } } = event;

    const { location, archiveData, textsData: { messages }, showModal, bulkFilesUpdate } = this.props;

    const { restPath: folderPath } = Pages.getCurrentRouteInfo(location.pathname);

    const ids = archiveData.filter(({ path = [] }) => this.checkFolderMatch([[...folderPath, folder], path]))
      .map(({ id }) => id);

    showModal(
      Utils.replaceTextVars(messages.folderDelete, { name: folder }), null, true
    ).then((result) => {
      if (result) bulkFilesUpdate(ids);
    });
  }

  @bind
  handleChangeFolderSelectorChange(folder) {
    const { bulkFilesUpdate, toggleModalContent } = this.props;

    if (folder) {
      bulkFilesUpdate(this.state.selectedItems, this.getFolderPath(folder));
      this.setState({ selectedItems: [] });
    } else {
      toggleModalContent(true);
      this.setState({ createFolderWindowOpened: true });
    }
  }

  @bind
  handleBulkDocumentsAddTags() {
    this.props.toggleModalContent(true);
    this.setState({ addTagsWindowOpened: true });
  }

  @bind
  handleBulkFilesDelete() {
    const { textsData: { messages }, showModal, bulkFilesUpdate } = this.props;

    const { selectedItems } = this.state;

    showModal(
      Utils.replaceTextVars(
        messages.bulkFilesDeleteConfirm,
        { filesCount: selectedItems.length }
      ),
      null,
      true
    ).then((result) => {
      if (result) bulkFilesUpdate(selectedItems);
      this.setState({ selectedItems: [] });
    });
  }

  @bind
  handleFilesUnselect() {
    this.setState({ selectedItems: [] });
  }

  @bind
  handleTableFileDownload(fileId) {
    const { archiveData, downloadFile } = this.props;

    const { attachment } = archiveData.find(({ id }) => id === fileId);

    downloadFile(fileId, attachment);
  }

  @bind
  handleTableFileEdit(fileId, initialTab = null) {
    this.props.toggleModalContent(true);
    this.setState(
      { editVaultFileWindowOpened: true, editableVaultFileData: { id: fileId, tab: initialTab } },
      () => {
        const { envVars, history } = this.props;

        history.replace(
          `?${Utils.objectToQueryString({ ...envVars, editItem: `${fileId}${initialTab ? `.${initialTab}` : ""}` })}`
        );
      }
    );
  }

  @bind
  handleTableFileDelete(fileId) {
    const { textsData: { messages }, showModal, deleteFile } = this.props;

    showModal(messages.fileDeleteConfirm, null, true)
      .then((result) => {
        if (result) deleteFile(fileId);
      });
  }

  @bind
  async handleCreateFolderWindowClose(result) {
    const { bulkFilesUpdate, createFolder } = this.props;

    const { selectedItems, addVaultFileWindowOpened } = this.state;

    if (result) {
      if (this.state.addVaultFileWindowOpened) {
        this.setState({ addVaultFileWindowData: { folderName: result } });
      } else if (selectedItems.length) {
        await this.createFolderIfNeed(this.getFolderPath(result));
        bulkFilesUpdate(selectedItems, this.getFolderPath(result));
        this.setState({ selectedItems: [] });
      } else {
        createFolder({ path: this.getFolderPath(result) });
      }
    } else if (addVaultFileWindowOpened) this.setState({ addVaultFileWindowOpened: false });
    this.setState({ createFolderWindowOpened: false });
  }

  @bind
  async handleAddVaultFileWindowClose(result) {
    this.setState({ addVaultFileWindowOpened: false, addVaultFileWindowData: null });
    if (result) {
      const { files, ...restResult } = result;

      await this.createFolderIfNeed(restResult.path);

      await this.props.uploadFiles(files, restResult, true);

      this.props.fetchFilesList(false, true);
    }
  }

  @bind
  handleEditVaultFileWindowClose(result) {
    const { fetchFilesList, editFile } = this.props;

    if (result) {
      const { id: fileId, ...restResult } = result;

      editFile(fileId, restResult).then((successful) => {
        if (successful) fetchFilesList(false, false);
      });
    } else fetchFilesList(false, true);
    this.setState(
      { editVaultFileWindowOpened: false, editableVaultFileData: null },
      () => {
        const { envVars, history } = this.props;

        const { editItem, ...restEnvVars } = envVars;

        history.replace(`?${Utils.objectToQueryString(restEnvVars)}`);
      }
    );
  }

  @bind
  handleAddTagsWindowClose(tags) {
    if (tags) this.props.bulkFilesUpdate(this.state.selectedItems, null, tags);
    this.setState({ addTagsWindowOpened: false });
  }

  @bind
  handleAddFolderButtonClick() {
    this.setState({ createFolderWindowOpened: true });
  }

  renderAddButtonBlock() {
    const { ADD_FILES_WINDOW_FOLDER_MODES } = VaultPage;

    const {
      textsData: { uiTexts },
      fetchingData,
      archiveFilesCurrentUploadCount
    } = this.props;

    return (
      <div>
        <DropDownSelect
          caret
          right
          size="sm"
          disabled={fetchingData || !!archiveFilesCurrentUploadCount}
          toggleContent={(
            <>
              {archiveFilesCurrentUploadCount ? <FiLoader data-wait /> : <FiPlusCircle />}
              <span>{uiTexts.uploadFiles}</span>
            </>
          )}
          options={[
            {
              label: <><FiFolder /><span>{uiTexts.inCurrentFolder}</span></>,
              value: ADD_FILES_WINDOW_FOLDER_MODES.CURRENT
            },
            {
              label: <><FiFolderPlus /><span>{uiTexts.inNewFolder}</span></>,
              value: ADD_FILES_WINDOW_FOLDER_MODES.NEW
            }
          ]}
          onChange={this.handleAddFilesSelectChange} />
      </div>
    );
  }

  renderNoDataContent(folders) {
    const { location, fetchingData, textsData: { uiTexts } } = this.props;

    if (fetchingData) return <Preloader />;

    const { restPath: folderPath } = Pages.getCurrentRouteInfo(location.pathname);

    return (
      <div className={CommonCss.noDataContent}>
        <div>
          <div>{folders.length ? <FiFile /> : <FiSave />}</div>
          <div>{folders.length
            ? Utils.replaceTextVars(uiTexts.noFilesInFolder, {
              folderName: folderPath[folderPath.length - 1] || "root"
            })
            : uiTexts.noFiles}</div>
        </div>
        {!folders.length && this.renderAddButtonBlock()}
      </div>
    );
  }

  render() {
    const { PARENT_FOLDER_RELATIVE_PATH } = VaultPage;

    const {
      textsData: { uiTexts },
      location,
      modalContentShown,
      archiveData,
      userRestrictions,
      fetchingData
    } = this.props;

    const {
      filters,
      selectedItems,
      createFolderWindowOpened,
      addVaultFileWindowOpened,
      editVaultFileWindowOpened,
      addTagsWindowOpened,
      addVaultFileWindowData,
      editableVaultFileData
    } = this.state;

    const { restPath: folderPath } = Pages.getCurrentRouteInfo(location.pathname);

    let filteredArchiveData = archiveData;

    let folders = [];

    const editableVaultFile = editableVaultFileData && this.getVaultFileDataById(editableVaultFileData.id);

    if (filters.fromDate && filters.toDate) {
      filteredArchiveData = filteredArchiveData.filter(({ fromDate, toDate }) => {
        const fromDateFilter = moment.utc(filters.fromDate);

        const toDateFilter = moment.utc(filters.toDate);

        fromDate = moment.utc(fromDate);
        toDate = moment.utc(toDate);

        return fromDate >= fromDateFilter && toDate <= toDateFilter;
      });
    }
    if (filters.text) {
      const pattern = new RegExp(filters.text.trim(), "i");

      filteredArchiveData = filteredArchiveData.filter(({ description, tags, attachment: { originalName } = {} }) => {
        return [description, originalName, ...tags]
          .some((value) => value && `${value}`.match(pattern));
      });
    }
    if (filters.type) {
      filteredArchiveData = filteredArchiveData.filter(({ type }) => type === filters.type);
    }
    if (filteredArchiveData.length) {
      folders = filteredArchiveData.reduce(
        (aggregator, { path = [], type }) => {
          if (path.length > folderPath.length) {
            if (this.checkFolderMatch([folderPath, path])) {
              const [folder] = path.slice(folderPath.length);

              let folderData = aggregator.find(({ name }) => name === folder);

              if (!folderData) {
                folderData = { name: folder, filesCount: 0 };
                aggregator.push(folderData);
              }

              if (type !== FOLDER) folderData.filesCount++;
            }
          }

          return aggregator;
        },
        []
      ).sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB));
      if (folderPath.length) folders = [{ name: PARENT_FOLDER_RELATIVE_PATH }, ...folders];
    }
    filteredArchiveData = filteredArchiveData.filter(({ path = [] }) => this.checkFolderMatch([folderPath, path, true]));

    const tagsList = [...new Set(filteredArchiveData.reduce((aggregator, { tags }) => [...aggregator, ...tags], []))];

    const tableData = filteredArchiveData.filter(({ type }) => type !== FOLDER);

    return (
      <Page className={Css.vaultPage}>
        <PageHeader fullPath>
          <div className={Css.pageHeaderContent}>
            <Button
              theme="light"
              size="sm"
              className={Css.createFolder}
              disabled={fetchingData}
              onClick={this.handleAddFolderButtonClick}>
              {uiTexts.createFolder}
            </Button>
            {!!(tableData.length || folders.length) && this.renderAddButtonBlock()}
          </div>
        </PageHeader>
        <Card>
          <CardBody>
            <PageActionsRow sticky={selectedItems.length || Object.values(filters).filter(Boolean).length}>
              <VaultFilter
                initialValue={filters}
                disabled={fetchingData}
                onChange={this.handleVaultFilterChange} />
              {!!(filteredArchiveData.length && selectedItems.length) && <>
                <div><span>{uiTexts.selected}: </span><b>{selectedItems.length}</b></div>
                <ChangeFolderSelector
                  folders={folders}
                  disabled={fetchingData}
                  onChange={this.handleChangeFolderSelectorChange} />
                <ActionsButtons
                  className={Css.actionsButtons}
                  disabled={fetchingData}
                  disabledDelete={userRestrictions.archiveDelete}
                  onAddTags={this.handleBulkDocumentsAddTags}
                  onDelete={this.handleBulkFilesDelete} />
                <ActionsButtons
                  className={Css.actionsButtons}
                  onUnselect={this.handleFilesUnselect} />
              </>}
            </PageActionsRow>
            <div className={Css.foldersRow}>
              {!!folders.length && folders.map(({ name, filesCount }) => {
                return (
                  <Button
                    outline
                    theme="light"
                    size="sm"
                    key={name}
                    data-folder={name}
                    disabled={fetchingData}
                    onClick={this.handleFolderButtonClick}>
                    <div>
                      {name === PARENT_FOLDER_RELATIVE_PATH
                        ? <FiArrowLeft />
                        : <>
                          <FiFolder />
                          <span className={Css.folderName}>{name}</span>
                          <Badge className={Css.folderFilesCount} value={filesCount} />
                          <span className={Css.delete} data-folder={name} onClick={this.handleDeleteClick}>
                            <FiTrash />
                          </span>
                        </>}
                    </div>
                  </Button>
                );
              })}
            </div>
            {tableData.length
              ? <MediaQuery maxWidth={Constants.SIMPLIFIED_LAYOUT_MAX_WIDTH}>
                {(matches) => (
                  <VaultTable
                    userRestrictions={userRestrictions}
                    data={tableData}
                    selectedItems={selectedItems}
                    folderPath={folderPath}
                    disabled={fetchingData}
                    simplifiedLayout={!!matches}
                    shouldUpdate={!modalContentShown}
                    onItemsSelect={this.handleTableItemsSelect}
                    onFileDownload={this.handleTableFileDownload}
                    onFileEdit={this.handleTableFileEdit}
                    onFileDelete={this.handleTableFileDelete} />
                )}
              </MediaQuery>
              : this.renderNoDataContent(folders)}
          </CardBody>
          {createFolderWindowOpened && <CreateFolderWindow
            folders={folders}
            onClose={this.handleCreateFolderWindowClose} />}
          {addVaultFileWindowOpened && !createFolderWindowOpened && <EditVaultFileWindow
            folderPath={folderPath}
            additionalData={addVaultFileWindowData}
            onClose={this.handleAddVaultFileWindowClose} />}
          {editVaultFileWindowOpened && editableVaultFile && <EditVaultFileWindow
            initialTab={editableVaultFileData.tab}
            editData={this.getVaultFileDataById(editableVaultFileData.id)}
            onClose={this.handleEditVaultFileWindowClose} />}
          {addTagsWindowOpened && <AddTagsWindow
            autoCompleteData={tagsList}
            onClose={this.handleAddTagsWindowClose} />}
        </Card>
      </Page>
    );
  }
}

export default VaultPage;
