import { Badge, ButtonRadioGroup } from "lib/common";
import { FiEdit, FiMessageCircle } from "react-icons/fi";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import React, { PureComponent } from "react";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class EditVaultFileWindowModeSelector extends PureComponent {
  static MODES = {
    DEFAULT: "default",
    COMMENTS: "comments"
  };

  render() {
    const { MODES } = EditVaultFileWindowModeSelector;

    const {
      textsData: { uiTexts },
      comments = {},
      selectedEditTab,
      onChange
    } = this.props;

    return (
      <ButtonRadioGroup
        outline
        size="sm"
        theme="light"
        key={selectedEditTab}
        initialValue={selectedEditTab}
        options={[
          {
            value: MODES.DEFAULT,
            label: <><FiEdit /><span>{uiTexts.data}</span></>
          },
          {
            value: MODES.COMMENTS,
            label: <>
              <FiMessageCircle />
              <span>{uiTexts.comments}</span>
              {!!comments.all && selectedEditTab !== MODES.COMMENTS && <Badge
                counter
                data-unread={comments.unread ? "" : undefined}
                value={comments.all} />}
            </>
          }
        ]}
        onChange={onChange} />
    );
  }
}

export const { MODES: EDIT_VAULT_FILE_WINDOW_MODES } = EditVaultFileWindowModeSelector;

export default EditVaultFileWindowModeSelector;
