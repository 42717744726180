import Css from "./style.module.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

const PageActionsRow = ({ sticky, children, className, ...restProps }) => {
  const [stuck, setStuck] = useState();

  const siblingRef = useRef();

  const actionRowRef = useRef();

  const applyStyles = useCallback(() => {
    const sibling = siblingRef.current;

    const actionRow = actionRowRef.current;

    if (!sibling || !actionRow) return;

    setStuck(sibling.getBoundingClientRect().top !== actionRow.getBoundingClientRect().top);
  }, []);

  const handleScroll = useCallback(() => {
    applyStyles();
  }, [applyStyles]);

  useEffect(() => {
    if (sticky) {
      applyStyles();

      const scrollContainer = document.getElementById("scroll-container");

      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", handleScroll);

        return () => {
          scrollContainer.removeEventListener("scroll", handleScroll);
        };
      }
    }
    setStuck(false);

    return undefined;
  }, [applyStyles, handleScroll, sticky]);

  return (
    <>
      <div ref={siblingRef} className={Css.sibling} />
      <div
        {...restProps}
        ref={actionRowRef}
        className={classNames(Css.pageActionsRow, !!sticky && Css.sticky, stuck && Css.stuck, className)}>
        {children}
      </div>
    </>
  );
};

export default PageActionsRow;
