import CommonCss from "lib/common/style.module.scss";

import TableCss from "lib/common/Table/style.module.scss";

import { ActionsButtons, AutoTooltip, Table } from "lib/common";
import { EDIT_VAULT_FILE_WINDOW_MODES } from "lib/pages/VaultPage/lib/EditVaultFileWindow/lib/EditVaultFileWindowModeSelector";
import { FaCommentDots, FaRegCommentDots } from "react-icons/fa";
import { FiFile, FiUser } from "react-icons/fi";
import { FormCheckbox } from "shards-react";
import { bind } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import AbstractDataTable from "lib/common/AbstractDataTable";
import Constants from "const/Constants";
import React from "react";
import moment from "moment";

const SQUARE_CELL_WIDTH = 50;

const DATE_CELL_MAX_WIDTH = 130;

const FROM_TO_CELL_MAX_WIDTH = 100;

const TYPE_CELL_MAX_WIDTH = 140;

const NOTIFICATIONS_CELL_MAX_WIDTH = 80;

const ACTIONS_CELL_WIDTH = 100;

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class VaultTable extends AbstractDataTable {
  get tableColumns() {
    const {
      textsData: { uiTexts },
      disabled,
      simplifiedLayout,
      data,
      onFileDownload,
      onFileEdit,
      onFileDelete
    } = this.props;

    const { checkBoxes } = this.state;

    return [
      {
        accessor: "id",
        sortable: false,
        width: SQUARE_CELL_WIDTH,
        className: TableCss.squareCell,
        headerClassName: TableCss.squareCell,
        Header: <FormCheckbox
          checked={data.length && !data.some(({ id }) => !checkBoxes[id])}
          disabled={disabled || !data.length}
          onChange={this.handleHeaderCheckBoxChange} />,
        Cell: ({ value }) => {
          return (
            <FormCheckbox
              data-id={value}
              checked={!!checkBoxes[value]}
              disabled={disabled}
              onChange={this.handleCheckBoxChange} />
          );
        }
      },
      {
        accessor: "attachment.originalName",
        clickable: true,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.attachment,
        Cell: ({ value = null }) => {
          if (!value) return Constants.EMPTY_PLACEHOLDER;

          return (
            <AutoTooltip>
              <FiFile />
              <span>{value}</span>
            </AutoTooltip>
          );
        }
      },
      {
        accessor: "type",
        clickable: true,
        maxWidth: TYPE_CELL_MAX_WIDTH,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.type,
        Cell: ({ value }) => {
          return (
            <span title={(value && uiTexts[value]) || ""}>
              {value ? uiTexts[value] : Constants.EMPTY_PLACEHOLDER}
            </span>
          );
        }
      },
      {
        accessor: "createdBy.fullName",
        clickable: true,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.uploadedBy,
        Cell: ({ value = null }) => {
          if (!value) return Constants.EMPTY_PLACEHOLDER;

          return (
            <AutoTooltip>
              <FiUser />
              <span>{value}</span>
            </AutoTooltip>
          );
        }
      },
      {
        accessor: "createdAt",
        clickable: true,
        maxWidth: DATE_CELL_MAX_WIDTH,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.created,
        Cell: ({ value }) => {
          const content = value
            ? moment.duration(moment.utc(value).diff(moment.utc())).humanize(true)
            : Constants.EMPTY_PLACEHOLDER;

          return <AutoTooltip>{content}</AutoTooltip>;
        }
      },
      {
        accessor: "fromDate",
        clickable: true,
        maxWidth: FROM_TO_CELL_MAX_WIDTH,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.fromDate,
        Cell: ({ value }) => {
          return (
            <AutoTooltip active={!!value}>
              {value
                ? moment.utc(value).format(Constants.DATETIME_FORMATS.DATE_TEXT)
                : Constants.EMPTY_PLACEHOLDER}
            </AutoTooltip>
          );
        }
      },
      {
        accessor: "toDate",
        clickable: true,
        maxWidth: FROM_TO_CELL_MAX_WIDTH,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.toDate,
        Cell: ({ value }) => {
          return (
            <AutoTooltip active={!!value}>
              {value
                ? moment.utc(value).format(Constants.DATETIME_FORMATS.DATE_TEXT)
                : Constants.EMPTY_PLACEHOLDER}
            </AutoTooltip>
          );
        }
      },
      {
        accessor: "description",
        clickable: true,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.description,
        Cell: ({ value }) => <AutoTooltip active={!!value}>{value || Constants.EMPTY_PLACEHOLDER}</AutoTooltip>
      },
      {
        accessor: "tags",
        clickable: true,
        show: !simplifiedLayout,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.tags,
        Cell: ({ value: tags = [] }) => {
          if (!tags.length) return Constants.EMPTY_PLACEHOLDER;

          return (
            <span title={tags.join(", ")} className={TableCss.tagsCell}>
              <span>{tags[0]}</span>
              {tags.length > 1 && <span data-rest>{`...+${tags.length - 1}`}</span>}
            </span>
          );
        }
      },
      {
        sortable: false,
        show: !simplifiedLayout,
        maxWidth: NOTIFICATIONS_CELL_MAX_WIDTH,
        className: TableCss.notificationsCell,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.comments,
        Cell: ({ original: { id, comments: { all, unread } = {} } }) => {
          return (
            <span
              title={all || uiTexts.addComment}
              data-id={id}
              className={unread ? CommonCss.negativeText : undefined}
              onClick={this.handleNotificationsLinkClick}>
              {all
                ? <>
                  <FaCommentDots />
                  <span><b>{all}</b></span>
                </>
                : <FaRegCommentDots />}
            </span>
          );
        }
      },
      {
        id: "actions",
        sortable: false,
        width: ACTIONS_CELL_WIDTH,
        className: CommonCss.flexEnd,
        Header: uiTexts.actions,
        Cell: ({ original: { id } }) => {
          return (
            <ActionsButtons
              itemId={id}
              disabledDelete={this.props.userRestrictions.archiveDelete}
              onDownload={onFileDownload}
              onEdit={onFileEdit}
              onDelete={onFileDelete} />
          );
        }
      }
    ];
  }

  @bind
  getTrProps(state, { original: { notRead } }) {
    return { ...(notRead ? { "data-highlight": "" } : {}), disabled: this.props.disabled };
  }

  @bind
  getTdProps(state, { original: { id } }, { clickable }) {
    const { disabled, onFileEdit } = this.props;

    if (!clickable || disabled) return {};

    return {
      style: { cursor: "pointer" },
      onClick: () => { onFileEdit(id); }
    };
  }

  componentDidMount() {
    this.props.onItemsSelect([]);
  }

  @bind
  handleNotificationsLinkClick({ currentTarget: { dataset: { id } } }) {
    this.props.onFileEdit(id, EDIT_VAULT_FILE_WINDOW_MODES.COMMENTS);
  }

  render() {
    const { data } = this.props;

    return (
      <Table
        showPageSizeOptions
        columns={this.tableColumns}
        data={data}
        showPagination={data.length > Constants.TABLE_PAGE_SIZE}
        getTrProps={this.getTrProps}
        getTdProps={this.getTdProps} />
    );
  }
}

export default VaultTable;
