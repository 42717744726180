// TODO: Replace this Base class by HOC or other React-friendly solutions
import { Component } from "react";
import { bind } from "decko";
import DataConstants from "const/DataConstants";

export default class AbstractDataTable extends Component {
  static getDerivedStateFromProps({ data, selectedItems }) {
    return { checkBoxes: Object.fromEntries(data.map(({ id }) => [id, selectedItems.includes(id)])) };
  }

  state = { checkBoxes: {}, data: {} };

  get selectedItems() {
    return Object.entries(this.state.checkBoxes).filter(([, value]) => value).map(([key]) => key);
  }

  @bind
  getPaginationProps() {
    if (this.props.disabled) return { className: "disabled" };

    return {};
  }

  toggleItemSelection(id) {
    let { selectedItems = [] } = this;

    if (selectedItems.includes(id)) selectedItems = selectedItems.filter((checkBoxId) => checkBoxId !== id);
    else selectedItems = selectedItems = [...selectedItems, id];
    this.props.onItemsSelect(selectedItems);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.shouldUpdate === undefined || nextProps.shouldUpdate;
  }

  componentDidMount() {
    this.props.onItemsSelect([]);
  }

  componentDidUpdate(prevProps) {
    const { data, disabled, onItemsSelect } = this.props;

    if (!disabled) {
      let dataChanged = prevProps.data.length !== data.length;

      if (!dataChanged) dataChanged = data.some(({ id, status, path = [] }, index) => {
        const { id: prevId, status: prevStatus, path: prevPath = [] } = prevProps.data[index];

        return id !== prevId
          || status !== prevStatus
          || path.length !== prevPath.length
          || path.some((pathPart, pathPartIndex) => pathPart !== prevPath[pathPartIndex]);
      });

      if (dataChanged) onItemsSelect([]);
    }
  }

  @bind
  handleHeaderCheckBoxChange({ target: { checked } }) {
    let { data, onItemsSelect } = this.props;

    data = data.filter(({ status }) => status !== DataConstants.STATUSES.TO_EXTRACT);
    onItemsSelect(checked ? data.map(({ id }) => id) : []);
  }

  @bind
  handleCheckBoxChange({ target: { dataset: { id } } }) {
    this.toggleItemSelection(id);
  }
}
