import Css from "./style.module.scss";

import { Button, InputGroup, InputGroupAddon } from "shards-react";
import { FiFilter } from "react-icons/fi";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DateTimeInput from "../DateTimeInput";
import React, { useCallback } from "react";
import classNames from "classnames";

const DateTimeRangePicker = ({
  className,
  disabled,
  showMonthYearPicker,
  minDate,
  maxDate,
  startDate,
  endDate,
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  onToggleClick
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const dateTimeEndRef = React.createRef();

  const handleFromDateChange = useCallback((value) => {
    onFromDateChange(value);
    if (dateTimeEndRef.current) {
      const input = dateTimeEndRef.current.querySelector(".dateTimeEnd");

      if (input) input.focus();
    }
  }, [dateTimeEndRef, onFromDateChange]);

  return (
    <div className={classNames(Css.dateTimeRangePicker, className)} ref={dateTimeEndRef}>
      <InputGroup>
        <DateTimeInput
          selectsStart
          disabled={disabled}
          showMonthYearPicker={showMonthYearPicker}
          minDate={minDate}
          maxDate={maxDate}
          startDate={startDate}
          endDate={endDate}
          value={fromDate}
          placeholder={uiTexts.fromDate}
          onChange={handleFromDateChange} />
        <DateTimeInput
          selectsEnd
          className="dateTimeEnd"
          disabled={disabled}
          showMonthYearPicker={showMonthYearPicker}
          minDate={minDate}
          maxDate={maxDate}
          startDate={fromDate}
          endDate={endDate}
          value={toDate}
          placeholder={uiTexts.toDate}
          onChange={onToDateChange} />
        <InputGroupAddon type="append">
          <Button
            theme={!!fromDate && !!toDate ? "primary" : "secondary"}
            disabled={disabled || !fromDate || !toDate}
            onClick={onToggleClick}>
            <FiFilter />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>

  );
};

export default React.memo(DateTimeRangePicker);
