import Css from "./style.module.scss";

import { DropDownSelect } from "lib/common";
import { FiChevronsRight, FiFolder, FiFolderPlus } from "react-icons/fi";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import React, { PureComponent } from "react";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class ChangeFolderSelector extends PureComponent {
  get optionsList() {
    const { textsData: { uiTexts }, folders } = this.props;

    return [
      ...folders.map(({ name }) => ({
        value: name,
        label: <><FiFolder /><span>{name}</span></>
      })),
      { value: "", label: <><FiFolderPlus /><span>{uiTexts.createFolder}</span></> }
    ];
  }

  render() {
    const { textsData: { uiTexts }, disabled, onChange } = this.props;

    return (
      <DropDownSelect
        caret
        right
        outline
        theme="secondary"
        size="sm"
        className={Css.changeFolderSelector}
        toggleContent={<><FiChevronsRight /><span>{`${uiTexts.moveThemTo}...`}</span></>}
        disabled={disabled}
        options={this.optionsList}
        onChange={onChange} />
    );
  }
}

export default ChangeFolderSelector;
