import Css from "./style.module.scss";

import { Button, FormSelect, InputGroup, InputGroupAddon } from "shards-react";
import { DebounceInput, Form } from "lib/common";
import { FiFilter, FiX } from "react-icons/fi";
import { bind } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import DataConstants from "const/DataConstants";
import DateTimeRangePicker from "lib/common/DateTimeRangePicker";
import React, { PureComponent } from "react";
import Utils from "utils/Utils";
import moment from "moment";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps, null)
class VaultFilter extends PureComponent {
  constructor(props) {
    super(props);

    const { initialValue: { fromDate, toDate, text, type } } = this.props;

    this.state = {
      dateRangeEnabled: !!(fromDate && toDate),
      textEnabled: !!text,
      typeEnabled: !!type,
      fromDate: fromDate || "",
      toDate: toDate || "",
      text: text || "",
      type: type || ""
    };
  }

  emitChangeEvent() {
    const { dateRangeEnabled, textEnabled, typeEnabled, fromDate, toDate, text, type } = this.state;

    const filters = {};

    if (dateRangeEnabled) filters.fromDate = fromDate;
    if (dateRangeEnabled) filters.toDate = toDate;
    if (textEnabled) filters.text = text;
    if (typeEnabled) filters.type = type;
    this.props.onChange(filters);
  }

  @bind
  handleFromDateInputChange(value) {
    const { toDate } = this.state;

    const formattedValue = value ? Utils.formatApiDate(value) : "";

    this.setState({
      fromDate: formattedValue,
      toDate: (!value || !toDate || moment.utc(toDate).isBefore(moment.utc(value))) ? formattedValue : toDate,
      dateRangeEnabled: toDate
    }, () => toDate && this.emitChangeEvent());
  }

  @bind
  handleToDateInputChange(value) {
    const { fromDate } = this.state;

    const formattedValue = value ? Utils.formatApiDate(value) : "";

    this.setState({
      toDate: formattedValue,
      fromDate: (!value || !fromDate || moment.utc(value).isBefore(moment.utc(fromDate))) ? formattedValue : fromDate,
      dateRangeEnabled: fromDate
    }, () => fromDate && this.emitChangeEvent());
  }

  @bind
  handleTextInputChange({ target: { value } }) {
    this.setState({ text: value });
  }

  @bind
  handleTextInputInputComplete(value) {
    const text = value.trim();

    this.setState(
      () => ({ text, textEnabled: !!text }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleTypeSelectChange({ target: { value } }) {
    this.setState(
      () => ({ type: value, typeEnabled: !!value }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleDateRangeButtonClick() {
    this.setState(
      ({ dateRangeEnabled, fromDate, toDate }) => ({
        dateRangeEnabled: !dateRangeEnabled,
        fromDate: dateRangeEnabled ? "" : fromDate,
        toDate: dateRangeEnabled ? "" : toDate
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleTextButtonClick() {
    this.setState(
      ({ textEnabled, text }) => ({
        textEnabled: !textEnabled,
        text: textEnabled ? "" : text
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleTypeButtonClick() {
    this.setState(
      ({ typeEnabled, type }) => ({
        typeEnabled: !typeEnabled,
        type: typeEnabled ? "" : type
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleResetButtonClick() {
    this.setState({
      fromDate: "",
      toDate: "",
      text: "",
      type: "",
      typeEnabled: false,
      textEnabled: false,
      dateRangeEnabled: false
    }, () => this.emitChangeEvent());
  }

  render() {
    const { AGREEMENT, TAX_STATEMENT, BANK_STATEMENT, OTHER } = DataConstants.ARCHIVE_ATTACHMENT_TYPES;

    const { textsData: { uiTexts }, disabled } = this.props;

    const {
      dateRangeEnabled,
      textEnabled,
      typeEnabled,
      fromDate,
      toDate,
      text,
      type
    } = this.state;

    const hasFilters = dateRangeEnabled || textEnabled || typeEnabled;

    return (
      <div className={Css.vaultFilter}>
        <Form>
          <DateTimeRangePicker
            disabled={disabled}
            startDate={fromDate}
            endDate={toDate}
            fromDate={fromDate}
            toDate={toDate}
            onFromDateChange={this.handleFromDateInputChange}
            onToDateChange={this.handleToDateInputChange}
            onToggleClick={this.handleToggleDateRangeButtonClick} />
          <InputGroup>
            <DebounceInput
              disabled={disabled}
              value={text}
              placeholder={uiTexts.searchText}
              onChange={this.handleTextInputChange}
              onInputComplete={this.handleTextInputInputComplete} />
            <InputGroupAddon type="append">
              <Button
                theme={textEnabled ? "primary" : "secondary"}
                disabled={disabled || !text}
                onClick={this.handleToggleTextButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup>
            <FormSelect
              disabled={disabled}
              placeholder={type ? undefined : ""}
              value={type}
              onChange={this.handleTypeSelectChange}>
              {!type && <option value="">{uiTexts.type}</option>}
              <option value={AGREEMENT}>{uiTexts.agreement}</option>
              <option value={TAX_STATEMENT}>{uiTexts.taxStatement}</option>
              <option value={BANK_STATEMENT}>{uiTexts.bankStatement}</option>
              <option value={OTHER}>{uiTexts.other}</option>
              {type && <option value="">{uiTexts.all}</option>}
            </FormSelect>
            <InputGroupAddon type="append">
              <Button
                theme={typeEnabled ? "primary" : "secondary"}
                disabled={disabled || !type}
                onClick={this.handleToggleTypeButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          {hasFilters && (
            <InputGroup className={Css.resetButtonGroup}>
              <Button theme="danger" outline onClick={this.handleResetButtonClick}>
                <FiX />
                <span>{uiTexts.resetFilters}</span>
              </Button>
            </InputGroup>
          )}
        </Form>
      </div>
    );
  }
}

export default VaultFilter;
